/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF('/BirthdayNFT/vrgnft.gltf');
  return (
    <group ref={group} {...props} dispose={null} scale={12} rotation={[-250, 0, 0]}>
      <mesh
        geometry={nodes.Curve.geometry}
        material={materials['SVGMat.001']}
        scale={[14.43, 1, 14.43]}
      />
    </group>
  );
}

useGLTF.preload('/vrgnft.gltf');
